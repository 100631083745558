



























































import { Observer } from 'mobx-vue'
import { Component, Inject, Vue } from 'vue-property-decorator'
import { DiscoveryViewmodel } from '../../viewmodels/discovery-viewmodel'

@Observer
@Component
export default class extends Vue {
  @Inject() vm!: DiscoveryViewmodel
}
